import axios from '@/axios.js'

export default {
    getMenuPreview: async (menuUuid) => {
        try {
            const response = await axios.editorapi.get(`/v4/app-configurator/menus/${menuUuid}/preview`)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    getMenu: async (menuUuid) => {
        try {
            const response = await axios.editorapi.get(`/v4/app-configurator/menus/${menuUuid}`)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    getMenuList: async (params, page = 1) => {
        try {
            const response = await axios.editorapi.get(`/v4/app-configurator/menus`, {
                params: {
                    ...params,
                    page: page
                }
            })
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    deleteMenu: async (menuUUid) => {
        try {
            const response = await axios.editorapi.delete(`/v4/app-configurator/menus/${menuUUid}`)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
    editMenu: async (menuUUid, body) => {
        const response = await axios.editorapi.patch(`/v4/app-configurator/menus/${menuUUid}`, body)
        return response.data
    },
    createMenu: async (menuUUid, body) => {
        try {
            const response = await axios.editorapi.post(`/v4/app-configurator/menus/${menuUUid}`, body)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    }
}
